import { createAction, props } from '@ngrx/store';
import { Menu } from '../../interfaces/menu.interface';

export enum LayoutActionsTypes {
    getMenu = '[Layout] Get menu',
    successGetMenu = '[Layout] Success get menu',
    errorGetMenu = '[Layout] Error Get Menu'
}

export const getMenu = createAction(LayoutActionsTypes.getMenu);
export const successGetMenu = createAction(LayoutActionsTypes.successGetMenu, props<{ menu: Menu }>());
export const errorGetMenu = createAction(LayoutActionsTypes.errorGetMenu, props<{ payload: any }>());
